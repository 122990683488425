import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import Card from 'common/src/components/Card';
import Image from 'common/src/components/Image';
import Container from 'common/src/components/UI/Container';
import Ramesh from 'common/src/assets/image/hosting/Team/Ramesh.jpg';
import { TeamWrapper, PartnerWrapper } from '../team2.style';
import '../team2.css';

import { Link } from 'gatsby';

import Nar from 'common/src/assets/image/hosting/Team/Nar-Koppula.jpg';
import Aravind from 'common/src/assets/image/hosting/aravind.jpeg';
import Lax from 'common/src/assets/image/hosting/Team/Lax-Chepuri.jpg';
import Senthil from 'common/src/assets/image/hosting/sentil.png';
import virtues from 'common/src/assets/image/hosting/Team/virtues.png';
import hm from 'common/src/assets/image/hosting/Team/HM.png';
import salescatcher from 'common/src/assets/image/hosting/Team/Salescatcher.png';
import Cnetric from 'common/src/assets/image/hosting/Team/cognito.png';
import Tachogen from 'common/src/assets/image/hosting/Team/Tachogen.png';
import Digitalign from 'common/src/assets/image/hosting/Team/digitalign.jpg';
import Award1 from 'common/src/assets/image/hosting/awards/award-1.png';
import Award2 from 'common/src/assets/image/hosting/awards/award-2.png';
import Award3 from 'common/src/assets/image/hosting/awards/award-3.png';
import Award4 from 'common/src/assets/image/hosting/awards/award-4.png';
import Award5 from 'common/src/assets/image/hosting/awards/award-5.png';

import Feature1 from 'common/src/assets/image/hosting/feature/feature1.png';
import Feature2 from 'common/src/assets/image/hosting/feature/feature2.png';
import Feature3 from 'common/src/assets/image/hosting/feature/feature3.png';
import Feature4 from 'common/src/assets/image/hosting/feature/feature4.png';
import Feature5 from 'common/src/assets/image/hosting/feature/feature5.png';
import Virtues from 'common/src/assets/image/hosting/virtues.jpeg';
import HomeCTA from '../../HomeCta/HomeCtasection';
const Team3section = ({
  row,
  col1,
  col2,
  colA,
  title,
  description,
  Teamtitle,
  Teamdescription,
}) => {
  return (
    <>
      <Box>
        <TeamWrapper>
          <Box {...row} className="flexjustifycenter">
            <Box className="team_card" {...colA}>
              <Box className="team_image_wrapper">
                <a href="https://www.linkedin.com/in/lax-rao-chepuri-9325362/">
                  <Image src={Lax} alt="Lax (Rao) Chepuri" />
                </a>
              </Box>
              <Box className="team_text_wrapper">
                <Box className="name_plate">
                  <Heading {...Teamtitle} content="Lax Chepuri" />
                  <Text {...Teamdescription} content="Advisor" />
                </Box>
              </Box>
            </Box>
            <Box className="team_card" {...colA}>
              <Box className="team_image_wrapper">
                <a href="https://www.linkedin.com/in/narkoppula/">
                  <Image src={Nar} alt="Nar Koppula" />
                </a>
              </Box>
              <Box className="team_text_wrapper">
                <Box className="name_plate">
                  <Heading {...Teamtitle} content="Nar Koppula" />
                  <Text {...Teamdescription} content="Advisor" />
                </Box>
              </Box>
            </Box>
            <Box className="team_card" {...colA}>
              <Box className="team_image_wrapper">
                <a href="https://www.linkedin.com/in/vcramesh1/">
                  <Image src={Ramesh} alt="Ramesh Kumar" />
                </a>
              </Box>
              <Box className="team_text_wrapper">
                <Box className="name_plate">
                  <Heading {...Teamtitle} content="Ramesh Kumar" />
                  <Text {...Teamdescription} content="Advisor" />
                </Box>
              </Box>
            </Box>
            <Box className="team_card" {...colA}>
              <Box className="team_image_wrapper">
                <a href="https://www.linkedin.com/in/arvind-nerella-7170338/">
                  <Image src={Aravind} alt="Arvind Nerella" />
                </a>
              </Box>
              <Box className="team_text_wrapper">
                <Box className="name_plate">
                  <Heading {...Teamtitle} content="Arvind Nerella" />
                  <Text {...Teamdescription} content="Advisor" />
                </Box>
              </Box>
            </Box>
            <Box className="team_card" {...colA}>
              <Box className="team_image_wrapper">
                <a href="https://www.linkedin.com/in/senthil-kumar-deivasigamany">
                  <Image src={Senthil} alt="" />
                </a>
              </Box>
              <Box className="team_text_wrapper">
                <Box className="name_plate">
                  <Heading
                    {...Teamtitle}
                    content="Senthil Kumar Deivasigamany"
                  />
                  <Text {...Teamdescription} content="Advisor" />
                </Box>
              </Box>
            </Box>
          </Box>
        </TeamWrapper>
        <TeamWrapper>
          <Heading {...title} content="Awards" className="text_center" />
          <Box
            {...row}
            className="flexjustifycenter"
            style={{ alignItems: 'center' }}
          >
            <Box className="team_card" {...colA}>
              <Box className="">
                <a href=" https://www.linkedin.com/feed/update/urn:li:activity:6993216342602502144">
                  <Image src={Award3} alt="ITServe Synergy" />
                </a>
              </Box>
            </Box>
            <Box className="team_card" {...colA}>
              <Box className="">
                <a href="https://rapidautomation.ai/news/analytics-insights-magazine/">
                  <Image src={Award5} alt="Analytics Insight" className="ml2" />
                </a>
              </Box>
            </Box>
            <Box className="team_card" {...colA}>
              <Box className="">
                <a href="https://rapidautomation.ai/news/cio-review/">
                  <Image src={Award1} alt="CIO Review" />
                </a>
              </Box>
            </Box>
            <Box className="team_card" {...colA}>
              <Box className="">
                <a href=" https://industrywired.com/Top-Revolutionary-Digital-Transformation-Companies-in-the-World/#page=1">
                  <Image src={Award4} alt=" IndustryWired" />
                </a>
              </Box>
            </Box>
            <Box className="team_card" {...colA}>
              <Box className="">
                <a href="https://www.cioinsiderindia.com/vendor/rapid-acceleration-partners-opening-doorway-to-the-future-of-automation-cid-1194.html">
                  <Image src={Award2} alt="CIOInsider " />
                </a>
              </Box>
            </Box>
          </Box>
        </TeamWrapper>
        <TeamWrapper style={{ background: '#9e9e9e45' }} className="pad3">
          <Heading {...title} content="Feature" className="text_center" />
          <Box
            {...row}
            className="flexjustifycenter mt4"
            style={{ alignItems: 'center' }}
          >
            <Box className="team_card" {...colA}>
              <Box className="">
                <a href=" https://www.linkedin.com/feed/update/urn:li:activity:6993216342602502144">
                  <Image
                    src={Feature1}
                    alt="ITServe Synergy"
                    className="wid10"
                  />
                </a>
              </Box>
            </Box>
            <Box className="team_card" {...colA}>
              <Box className="">
                <a href="https://rapidautomation.ai/news/analytics-insights-magazine/">
                  <Image
                    src={Feature5}
                    alt="Analytics Insight"
                    className="wid10"
                  />
                </a>
              </Box>
            </Box>
            <Box className="team_card" {...colA}>
              <Box className="">
                <a href="https://rapidautomation.ai/news/cio-review/">
                  <Image src={Feature4} alt="CIO Review" className="wid10" />
                </a>
              </Box>
            </Box>
            <Box className="team_card" {...colA}>
              <Box className="">
                <a href=" https://industrywired.com/Top-Revolutionary-Digital-Transformation-Companies-in-the-World/#page=1">
                  <Image
                    src={Feature3}
                    alt=" IndustryWired"
                    className="wid10"
                  />
                </a>
              </Box>
            </Box>
            <Box className="team_card" {...colA}>
              <Box className="">
                <a href="https://www.cioinsiderindia.com/vendor/rapid-acceleration-partners-opening-doorway-to-the-future-of-automation-cid-1194.html">
                  <Image src={Feature2} alt="CIOInsider " className="wid10" />
                </a>
              </Box>
            </Box>
          </Box>
        </TeamWrapper>
      </Box>
      {/* <HomeCTA /> */}
      <Box>
        <PartnerWrapper>
          <Heading {...title} className="text_center" content="Partners" />
          {/* <Text
          {...description}
          className="text_center"
          content="We’re proud to work with partners from a range of industries."
        /> */}
          <Box {...row} className="flexjustifycenter companies">
            <Box>
              <Image src={Virtues} alt="Virtues" />
            </Box>
            <Box>
              <Image src={salescatcher} alt="Sales Catcher" />
            </Box>
            <Box>
              <Image src={hm} alt="HM" />
            </Box>
            <Box>
              <Image src={Cnetric} alt="Cnetric" />
            </Box>
            <Box>
              <Image src={Tachogen} alt="Tachogen" />
            </Box>
            <Box>
              <Image src={Digitalign} alt="Digitalign" />
            </Box>
          </Box>
        </PartnerWrapper>
      </Box>
    </>
  );
};

Team3section.propTypes = {
  row: PropTypes.object,
  col1: PropTypes.object,
  col2: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  Teamtitle: PropTypes.object,
  Teamdescription: PropTypes.object,
};

Team3section.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  col1: {
    width: [1, '49%', '49%', '33%', '33%'],
  },
  col2: {
    width: [1, '25%', '25%', '25%', '25%'],
    mt: ['0px', '50px', '50px', '50px', '50px'],
  },
  colA: {
    width: [1, '20%', '20%', '20%', '20%', '20%'],
    mt: ['0px', '50px', '50px', '50px', '50px'],
  },
  title: {
    fontSize: ['30px', '30px', '35px', '40px', '45px'],
    fontWeight: '300',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    pt: ['10px', '15px', '15px', '20px', '20px'],
    pb: ['10px', '15px', '15px', '20px', '20px'],
  },
  description: {
    fontSize: ['16px', '18px', '18px', '18px', '18px'],
    // color: '#343d48cc',
    color: '#252525',
    lineHeight: '1.5',
    mt: ['10px', '15px', '15px', '20px', '20px'],
    mb: ['10px', '15px', '15px', '20px', '20px'],
  },
  Teamtitle: {
    fontSize: ['16px', '16px', '18px', '18px', '18px'],
    // color: '#0f2137',
    color: 'rgb(119, 127, 142)',
    pt: 0,
    pb: 0,
    mb: '7px',
    textAlign: 'center',
    className: 'Teamtitle',
  },
  Teamdescription: {
    fontSize: ['12px', '12px', '14px', '14px', '14px'],
    // color: '#252525',
    color: 'rgb(119, 127, 142)',
    mt: 0,
    mb: 0,
    textAlign: 'center',
    className: 'Teamdescription',
  },
};

export default Team3section;
