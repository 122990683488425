import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import Card from 'common/src/components/Card';
import Image from 'common/src/components/Image';
import Container from 'common/src/components/UI/Container';

import { TeamWrapper, PartnerWrapper } from './team2.style';
import './team2.css';

import { Link } from 'gatsby';

import Raghu from 'common/src/assets/image/hosting/Team/Raghu.jpg';
import Baskar from 'common/src/assets/image/hosting/baskar_new.jpeg';
import Ramesh from 'common/src/assets/image/hosting/Team/Ramesh.jpg';
import Padmashali from 'common/src/assets/image/hosting/Team/Padmashali.jpg';
import Sundararaman from 'common/src/assets/image/hosting/Team/Sundarraman.jpg';
import Nagaraj from 'common/src/assets/image/hosting/Team/Nagaraj.jpg';
import Shyam from 'common/src/assets/image/hosting/Team/shyam.png';
import Lax from 'common/src/assets/image/hosting/lax.png';
import Aravind from 'common/src/assets/image/hosting/aravind.jpeg';
import Guru from 'common/src/assets/image/hosting/guru.png';

import Sai from 'common/src/assets/image/hosting/sai.png';

const Team2section = ({
  row,
  col1,
  col2,
  title,
  description,
  Teamtitle,
  Teamdescription,
}) => {
  return (
    <Box>
      <TeamWrapper>
        <Box {...row} className="flexjustifycenter">
          <Box className="team_card" {...col1}>
            <Box className="team_image_wrapper">
              <a href="https://www.linkedin.com/in/raghuramanramamurthy/">
                <Image src={Raghu} alt="Raghuraman Ramamurthy" />
              </a>
            </Box>
            <Box className="team_text_wrapper">
              <Box className="name_plate">
                <Heading {...Teamtitle} content="Raghuraman Ramamurthy" />
                <Text {...Teamdescription} content="Founder & CEO" />
              </Box>
            </Box>
          </Box>
          <Box className="team_card" {...col1}>
            <Box className="team_image_wrapper">
              <a href="https://www.linkedin.com/in/baskar-agneeswaran-8796b54/">
                <Image src={Baskar} alt="Baskar Agneeswaran" />
              </a>
            </Box>
            <Box className="team_text_wrapper">
              <Box className="name_plate">
                <Heading {...Teamtitle} content="Baskar Agneeswaran" />
                <Text {...Teamdescription} content="Founder & Director" />
              </Box>
            </Box>
          </Box>
        </Box>
      </TeamWrapper>
      <TeamWrapper>
        <Box {...row} className="flexjustifycenter">
          <Box className="team_card" {...col2}>
            <Box className="team_image_wrapper">
              <a href="https://www.linkedin.com/in/gyaneshwarpadmashali/">
                <Image src={Padmashali} alt="Padmashali Gyaneshwar" />
              </a>
            </Box>
            <Box className="team_text_wrapper">
              <Box className="name_plate">
                <Heading {...Teamtitle} content="Padmashali Gyaneshwar" />
                <Text {...Teamdescription} content="Head of Delivery" />
              </Box>
            </Box>
          </Box>

          <Box className="team_card" {...col2}>
            <Box className="team_image_wrapper">
              <a href=" https://www.linkedin.com/in/guru-gopalakrishnan">
                <Image src={Guru} alt="" />
              </a>
            </Box>
            <Box className="team_text_wrapper">
              <Box className="name_plate">
                <Heading
                  {...Teamtitle}
                  content="Gururaghavendran Gopalakrishnan"
                />
                <Text {...Teamdescription} content="VP of Engineering" />
              </Box>
            </Box>
          </Box>
          <Box className="team_card" {...col2}>
            <Box className="team_image_wrapper">
              <a href=" https://www.linkedin.com/in/lakshmi-narayanan-sundararajan-b2397117/">
                <Image src={Lax} alt="Lakshmi Narayanan Sundararajan" />
              </a>
            </Box>
            <Box className="team_text_wrapper">
              <Box className="name_plate">
                <Heading
                  {...Teamtitle}
                  content="Lakshmi Narayanan Sundararajan"
                />
                <Text {...Teamdescription} content="Senior Manager-Solutions" />
              </Box>
            </Box>
          </Box>
          <Box className="team_card" {...col2}>
            <Box className="team_image_wrapper">
              <a href="https://www.linkedin.com/in/sairam-sridhar/">
                <Image src={Sai} alt="Sairam Sridhar" />
              </a>
            </Box>
            <Box className="team_text_wrapper">
              <Box className="name_plate">
                <Heading {...Teamtitle} content="Sairam Sridhar" />
                <Text
                  {...Teamdescription}
                  content="Product Marketing Manager"
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </TeamWrapper>
    </Box>
  );
};

Team2section.propTypes = {
  row: PropTypes.object,
  col1: PropTypes.object,
  col2: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  Teamtitle: PropTypes.object,
  Teamdescription: PropTypes.object,
};

Team2section.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  col1: {
    width: [1, '49%', '49%', '33%', '33%'],
  },
  col2: {
    width: [1, '25%', '25%', '25%', '25%'],
    mt: ['0px', '50px', '50px', '50px', '50px'],
  },
  title: {
    fontSize: ['30px', '30px', '35px', '40px', '45px'],
    fontWeight: '300',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    pt: ['10px', '15px', '15px', '20px', '20px'],
    pb: ['10px', '15px', '15px', '20px', '20px'],
  },
  description: {
    fontSize: ['16px', '18px', '18px', '18px', '18px'],
    // color: '#343d48cc',
    color: '#252525',
    lineHeight: '1.5',
    mt: ['10px', '15px', '15px', '20px', '20px'],
    mb: ['10px', '15px', '15px', '20px', '20px'],
  },
  Teamtitle: {
    fontSize: ['16px', '16px', '18px', '18px', '18px'],
    // color: '#0f2137',
    color: 'rgb(119, 127, 142)',
    pt: 0,
    pb: 0,
    mb: '7px',
    textAlign: 'center',
    className: 'Teamtitle',
  },
  Teamdescription: {
    fontSize: ['12px', '12px', '14px', '14px', '14px'],
    // color: '#252525',
    color: 'rgb(119, 127, 142)',
    mt: 0,
    mb: 0,
    textAlign: 'center',
    className: 'Teamdescription',
  },
};

export default Team2section;
