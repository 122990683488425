import styled from "styled-components";

const TeamWrapper = styled.div`
  margin-top: 40px;
  .team_card {
    margin-bottom: 43px;
    &:hover {
      .Teamtitle,
      .Teamdescription {
        color: #252525;
      }
    }
    .team_image_wrapper {
      a {
        width: 200px;
        height: 200px;
        margin: 0 auto;
        margin-bottom: 20px;
        display: block;
      }
      img {
        border-radius: 50%;
      }
    }
    .team_text_wrapper {
      text-align: center;
      .name_plate {
        background: transparent;
        display: inline-block;
        margin: 0 auto;
        padding: 10px 15px;
        border-radius: 8px;
      }
    }
  }
`;

const PartnerWrapper = styled.div`
  img {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    max-width: 125px;
    max-height: 125px;
    object-fit: contain;
  }
  .companies > div {
    width: 12%;
    min-width: 125px;
    margin-right: 2.5%;
  }
`;

export { TeamWrapper, PartnerWrapper };
